import { Controller } from "stimulus"
import Turbolinks from "turbolinks"

const isBefore = (event, parentRow) => {
  const targetTop = parentRow.offset().top
  const midpoint = parentRow.outerHeight() / 2
  const mousePos = event.clientY

  return (mousePos - targetTop) < midpoint
}

export default class extends Controller {
  connect() {
    const self = this

    $(this.element)
      .find('td[draggable=true]')
      .on('dragstart', (e) => {
        const parentRow = $(e.target).parents('tr').first()
        e.originalEvent.dataTransfer.setData('text/plain', parentRow.data('item-id'))
        e.originalEvent.dataTransfer.effectAllowed = 'move'
        e.originalEvent.dataTransfer.setDragImage(e.target.parentElement, 0, 0)
      })
      .on('dragover', (e) => {
        e.preventDefault()
        const parentRow = $(e.target).parents('tr').first()

        if (isBefore(e, parentRow)) {
          $('tr').removeClass('highlight-top').removeClass('highlight-bottom')
          parentRow.addClass('highlight-top')
        } else {
          $('tr').removeClass('highlight-top').removeClass('highlight-bottom')
          parentRow.addClass('highlight-bottom')
        }

        e.originalEvent.dataTransfer.dropEffect = 'move'
      })
      .on('dragend', (e) => {
        e.preventDefault()
        $('tr').removeClass('highlight-top').removeClass('highlight-bottom')
      })
      .on('drop', (e) => {
        e.preventDefault()

        const parentRow = $(e.target).parents('tr').first()

        const woId = e.originalEvent.dataTransfer.getData('text/plain')
        const table_index = parseInt(parentRow.data('table-index'))
        const modifier = isBefore(e, parentRow) ? 'before' : 'after'

        $(self.element)
          .find('td[draggable=true]')
          .attr('draggable', false)
        Turbolinks.visit(`/work_orders/${woId}/reorder?table_index=${table_index}&modifier=${modifier}`)
      })
  }
}
