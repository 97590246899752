import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: Number }

  connect() {
    const self = this

    $(this.element).find('.ui.mark-complete.checkbox').checkbox({
      onChecked: function(event) {
        self.markComplete()
      }
    })

    $(this.element).on('click', function(event) {
      if (event.target.tagName !== 'LABEL') {
        Turbolinks.visit(`/tasks/${self.idValue}`)
      }
    })
  }

  markComplete() {
    const csrfParam = $('meta[name=csrf-param]').attr('content')
    const csrfToken = $('meta[name=csrf-token]').attr('content')

    const formElem = $('<form />')
      .attr('action', `/tasks/${this.idValue}/mark_complete`)
      .attr('method', 'post')
      .attr('accept-charset', 'UTF-8')
      .appendTo($('body'))

    formElem
      .append(`<input type="hidden" name="${csrfParam}" value="${csrfToken}">`)
      .append(`<input type="hidden" name="back_to" value="index">`)

    formElem.get(0).submit()
  }
}
