import { Controller } from "stimulus"
import { DateTime } from "luxon"

/**
 * 0 is unchecked, 1 is indeterminate, 2 is checked
 */
export default class extends Controller {
  connect() {
    this.status = 0
    this.allCheckbox = $(this.element).find('th .ui.checkbox')

    const self = this

    this.allCheckbox.checkbox({
      onChange: function(data) {
        switch (self.status) {
          case 0:
            self.checkAll()
            break
          case 1:
            self.uncheckAll()
            break
          case 2:
            self.uncheckAll()
            break
        }
      }
    })

    $(this.element)
      .find('td .ui.checkbox')
      .checkbox({
        onChange: function(data) {
          self.updateAllBox()
        }
      })

    this.initializeBatchFields()
  }

  updateAllBox() {
    const total = $('td .ui.checkbox').length
    const checked = $('td .ui.checked.checkbox').length

    if (checked === 0) {
      this.status = 0
      if (!$('#batch-actions').data('disable')) {
        $('#batch-actions').hide()
      }
      $('#filters').show()
      this.allCheckbox.checkbox('set unchecked')
      $('#batch-actions .field').addClass('disabled')
    } else if (checked < total) {
      this.status = 1
      $('#filters').hide()
      $('#batch-actions').show()
      this.allCheckbox.checkbox('set indeterminate')
      $('#batch-actions .field').removeClass('disabled')
    } else {
      this.status = 2
      $('#filters').hide()
      $('#batch-actions').show()
      this.allCheckbox.checkbox('set checked')
      $('#batch-actions .field').removeClass('disabled')
    }
  }

  checkAll() {
    $(this.element)
      .find('td .ui.checkbox')
      .checkbox('set checked')
    this.updateAllBox()
  }

  uncheckAll() {
    $(this.element)
      .find('td .ui.checkbox')
      .checkbox('set unchecked')
    this.updateAllBox()
  }

  initializeBatchFields() {
    const self = this

    $('#batch-actions')
      .find('.ui.calendar')
      .each(function() {
        const t = $(this).data('type')
        const msg = $(this).data('message')
        $(this).calendar({
          type: t,
          onChange: function(date) {
            const value = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
            const field = $(this).data('name')
            self.act(field, value, msg)
          }
        })
      })

    $('#batch-actions')
      .find('.dropdown[data-dropdown-target=dropdown] input[type=hidden]')
      .on('change', function(event) {
        const field = $(this).attr('name')
        const value = $(this).val()
        const msg = $(this).data('message')
        self.act(field, value, msg)
      })

    $('#batch-actions')
      .find('.dropdown[data-method=get]')
      .dropdown({
        onChange: function(value) {
          self.act(null, value, null, { method: 'get' })
        }
      })

    $('#batch-actions')
      .find('button')
      .on('click', function() {
        self.act(
          $(this).attr('name'),
          $(this).attr('value'),
          $(this).data('message') || null,
          { method: $(this).data('method') }
        )
      })
  }

  act(field, value, message = 'Are you sure?', options = {}) {
    if (message && window.confirm(message)) {
      this._act(field, value, options)
    } else if (options.method === 'get' && !message) {
      this._act(field, value, options)
    }
  }

  _act(field, value, options = {}) {
    const self = this

    const ids = $(self.element)
                  .find('td .ui.checked.checkbox')
                  .parents('tr')
                  .map(function() {
                    return $(this).data('item-id')
                  })
                  .get()

    if (options.method === 'get' && value) {
      const params = new URLSearchParams()

      ids.forEach(id => {
        params.append('ids[]', id)
      })

      const url = `${value}?${params.toString()}`

      Turbolinks.visit(url)
    } else {
      const form = $('#batch-actions form').get()[0]
      ids.forEach(id => {
        $(form).append(`<input type="hidden" name="ids[]" value="${id}" />`)
      })
      $(form).find('input[name=field]').val(field)
      $(form).append(`<input type="hidden" name="value" value="${value}" />`)
      form.submit()
    }
  }
}
