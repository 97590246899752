import { Controller } from "stimulus"
import _ from "lodash"

export default class extends Controller {
  static values = {
    pv: Array
  }

  // TODO: on charge add, add fields
  // TODO: on charge change, reset fields

  connect() {
    const self = this

    console.log(self.pvValue)

    this.objectField = $(this.element).find('input[name="lease[object_id]"]')
    this.objectField.on('change', function() {
      const tmp = $(this).val()
      if (tmp) {
        Turbolinks.visit(`/leases/new?object_id=${tmp}`, { action: 'replace' })
      }
    })
    self.initCheckboxes()
    self.initButtons()
  }

  addCharge(event) {
    event.preventDefault()
    event.stopPropagation()

    const self = this
    const idx = $(this.element).find('.charge-item-container .charge-item').length

    $(this.element).find('.charge-item-container').append(`
      <div class="charge-item" data-idx="${idx}">
        <div class="fields">
          <div class="four wide required field">
            <label for="lease_charge_items_attributes_${idx}_charge_id">Charge</label>
            <div class="ui selection dropdown" data-internal-init="1">
              <input type="hidden" id="lease_charge_items_attributes_${idx}_charge_id" name="lease[charge_items_attributes][${idx}][charge_id]" />
              <i class="dropdown icon"></i>
              <div class="default text">Charge</div>
              <div class="scrollhint menu">
                ${self.pvValue.map(pv => `<div class="item" data-value="${pv.id}">${pv.internal_desc}</div>`)}
              </div>
            </div>
          </div>
          <div class="five wide required field">
            <label for="lease_charge_items_attributes_${idx}_description">Description</label>
            <input type="text" id="lease_charge_items_attributes_${idx}_description" name="lease[charge_items_attributes][${idx}][description]" />
          </div>
          <div class="four wide required field">
            <label for="lease_charge_items_attributes_${idx}_amount">Amount ($/ft/mth)</label>
            <input type="text" id="lease_charge_items_attributes_${idx}_amount" name="lease[charge_items_attributes][${idx}][amount]" />
          </div>
          <div class="four wide field">
            <label for="lease_charge_items_attributes_${idx}_minimum_fixed_amount">Minimum Fixed Amount ($/mth)</label>
            <input type="text" id="lease_charge_items_attributes_${idx}_minimum_fixed_amount" name="lease[charge_items_attributes][${idx}][minimum_fixed_amount]" />
          </div>
          <div class="one wide field">
            <label>&nbsp;</label>
            <button class="ui charge-item-delete icon button">
              <i class="close icon"></i>
            </button>
          </div>
        </div>
      </div>
    `)

    self.initCheckboxes()
  }

  initCheckboxes() {
    const self = this

    $(this.element).find('.charge-item-container').find('.charge-item .ui.dropdown[data-internal-init=1]').dropdown({
      onChange: function(value) {
        const parent = $(this).parents('.charge-item')
        const idx = parent.attr('data-idx')
        const charge = _.find(self.pvValue, pc => pc.id == _.toInteger(value))

        if (!charge) {
          return
        }

        $(`#lease_charge_items_attributes_${idx}_description`).val(charge.description)
        $(`#lease_charge_items_attributes_${idx}_amount`).val(charge.amount)

        if (charge.amount_kind == 'per_ft') {
          $(`#lease_charge_items_attributes_${idx}_minimum_fixed_amount`).val(charge.minimum_fixed_amount)
          $(`#lease_charge_items_attributes_${idx}_minimum_fixed_amount`).parent('.field').removeClass('disabled')
        } else if (charge.amount_kind == 'fixed') {
          $(`#lease_charge_items_attributes_${idx}_minimum_fixed_amount`).val('')
          $(`#lease_charge_items_attributes_${idx}_minimum_fixed_amount`).parent('.field').addClass('disabled')
        }
      }
    }).attr('data-internal-init', null)
  }

  initButtons() {
    $(this.element).find('button.charge-item-delete').on('click', function(ev) {
      ev.preventDefault()
      $(this).parents('.charge-item').remove()
    })
  }
}
