import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const self = this
    this.amountKindField = $(this.element).find('input[name$="[amount_kind]"]')
    this.destinationField = $(this.element).find('input[name$="[minimum_fixed_amount]"]')

    if (this.amountKindField.val()) {
      self.handleAmountKind(this.amountKindField.val())
    }
    
    this.amountKindField.on('change', function() {
      self.handleAmountKind($(this).val())
    })
  }

  handleAmountKind(val) {
    if(val === 'fixed') {
      this.destinationField.parent().addClass('disabled')
      this.destinationField.val(null)
    }
    else {
      this.destinationField.parent().removeClass('disabled')
    }
  }
}
