import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const self = this

    this.typeField = $(this.element).find('input[name="type"]')

    this.typeField.on('change', function() {
      self.handleType()
    })
  }

  handleType() {
    const fd = new FormData(this.element)
    const params = new URLSearchParams()

    for (const [k, v] of fd.entries()) {
      if (k === 'type' || k === 'to_dashboard' || k.startsWith('work_order[')) {
        params.append(k, v)
      }
    }

    Turbolinks.visit(`/work_orders/new?${params.toString()}`, { action: 'replace' })
  }
}
