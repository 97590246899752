export const initRecordSearch = () => {
  $('.record-search.input input').on('change', function() {
    const params = new URLSearchParams(document.location.search)
    params.delete('page')
    params.set('q', $(this).val())
    Turbolinks.visit(`/tasks?${params.toString()}`)
  })

  $('.record-filter.modal').modal({
    autofocus: false,
    duration: 100
  })

  $('.record-filter.button').on('click', function() {
    $('.record-filter.modal').modal('show')
  })
}
