import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const self = this

    this.customerField = $(this.element).find('input[name="payment[customer_id]"]')
    this.customerField.on('change', function() {
      self.handleCustomerChange()
    })
  }

  handleCustomerChange() {
    const fd = new FormData(this.element)
    const path = document.location.pathname
    const params = new URLSearchParams()

    for (const [k, v] of fd.entries()) {
      if (k.startsWith('payment[')) {
        params.append(k, v)
      }
    }

    Turbolinks.visit(`${path}?${params.toString()}`, { action: 'replace' })
  }
}
