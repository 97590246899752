import $ from "jquery"
import { DateTime } from "luxon"

export const displayDates = () => {
  $('.date-display').each(function() {
    const date = DateTime.fromISO($(this).data('date'))

    $(this).text(date.toLocaleString())
  })

  $('.datetime-display').each(function() {
    const date = DateTime.fromISO($(this).data('datetime'))

    $(this).text(date.toLocaleString(DateTime.DATETIME_SHORT))
  })

  $('.time-display').each(function() {
    const date = DateTime.fromISO($(this).data('datetime'))

    $(this).text(date.toLocaleString(DateTime.TIME_SIMPLE))
  })
}
