import { Controller } from "stimulus"
import startsWith from "lodash/startsWith"

export default class extends Controller {
  static targets = [
    'action',
    'season'
  ]

  connect() {
    const self = this

    $(self.actionTarget).find('.ui.dropdown').dropdown({
      onChange: function(value) {
        if (startsWith(value, 'reserve_')) {
          $(self.seasonTarget).show()
        } else {
          $(self.seasonTarget).hide()
        }
      }
    })
  }
}
