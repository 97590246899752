import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    disableWhenNull: Array,
    dynamicArgs: Object,
    initial: Object,
    isDisabled: Boolean,
    modalSelector: String,
    source: String
  }

  static targets = ['dropdown', 'createButton']

  connect() {
    this.initializeDynamicArgs()
    this.clear()
    this.refreshState()
    this.setupModal()

    if (this.initialValue) {
      $(this.dropdownTarget)
        .dropdown('setup menu', { values: [this.initialValue] })
        .dropdown('set selected', this.initialValue.value, undefined, true)
    }
  }

  dynamicArgs() {
    if (!this.dynamicArgsValue) {
      return {}
    }

    const tmp = {}
    for (const arg in this.dynamicArgsValue) {
      const selector = $(this.dynamicArgsValue[arg])
      const val = selector.val()
      tmp[arg] = val
    }

    return tmp
  }

  initializeDynamicArgs() {
    const self = this

    for (const arg in this.dynamicArgsValue) {
      $(this.dynamicArgsValue[arg]).on('change', () => {
        self.clear()
        self.refreshState()
      })
    }
  }

  clear() {
    const src = this.sourceValue
    const params = new URLSearchParams()

    const args = this.dynamicArgs()
    for (const k in args) {
      params.append(k, args[k])
    }
    let addtl = params.toString()
    if (addtl) {
      addtl += '&'
    }

    const self = this

    $(this.dropdownTarget).dropdown({
      selectOnKeydown: false,
      forceSelection: false,
      onChange: (value) => {
        const ev = new CustomEvent('tenfour:change', { value: value })
        self.dropdownTarget.dispatchEvent(ev)
      },
      apiSettings: {
        cache: false,
        url: `${src}?${addtl}q={query}`,
        onResponse: res => {
          return {
            results: res.data.map(r => ({ value: r.id, name: r.name }))
          }
        }
      }
    })
  }

  refreshState() {
    let enabled = true

    if (this.disableWhenNullValue && this.disableWhenNullValue.length > 0) {
      const dynamicArgs = this.dynamicArgs()
      for (const arg in dynamicArgs) {
        enabled = enabled && !!dynamicArgs[arg]
      }
    }

    if (this.isDisabledValue) {
      enabled = false
    }

    if (enabled) {
      this.dropdown().removeClass('disabled')
    } else {
      this.dropdown().addClass('disabled')
    }

    if (enabled && this.hasCreateButtonTarget && $(this.modalSelectorValue).length) {
      $(this.createButtonTarget).off('click')
      $(this.createButtonTarget)
        .show()
        .on('click', event => {
          event.preventDefault()
          $(this.modalSelectorValue).modal('show')
        })
    } else if (this.hasCreateButtonTarget) {
      $(this.createButtonTarget).hide()
      $(this.createButtonTarget).off('click')
    }
  }

  csrfToken() {
    return $('head meta[name=csrf-token]').attr('content')
  }

  dropdown() {
    return $(this.dropdownTarget)
  }

  setupModal() {
    const self = this

    if ($(this.modalSelectorValue).length) {
      const dropdown = this.dropdown()
      const modal = $(this.modalSelectorValue)
      const button = $(`${this.modalSelectorValue} .actions .primary.button`)
      button.on('click', event => {
        event.preventDefault()

        $(modal).find('.ui.error.message').remove()

        const form = $(self.modalSelectorValue).find('form').get(0)

        if (!form) {
          return
        }

        const body = new FormData(form)

        body.append('authenticity_token', self.csrfToken())

        const additionalFields = self.dynamicArgs()
        for (const arg in additionalFields) {
          body.append(arg, additionalFields[arg])
        }

        button.addClass('loading')
        fetch(form.action, {
          method: 'POST',
          headers: {
            Accept: 'application/json'
          },
          body
        }).then(res => {
          button.removeClass('loading')

          return res.json().then(json => {
            return {
              result: json,
              status: res.status
            }
          })
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            const created = res.result.data

            dropdown.dropdown('setup menu', {
              values: [{ value: created.id, name: created.name }]
            })
            dropdown.dropdown('set selected', created.id)

            modal.modal('hide')
          } else if (res.result.errors) {
            $(modal)
              .find('.content')
              .prepend(
                `<div class="ui error message">
                  ${res.result.errors.join("<br>")}
                </div>`
              )
          } else {
            $(modal)
              .find('.content')
              .prepend(
                `<div class="ui error message">
                  Error saving customer
                </div>`
              )
          }
        })
      })
    }
  }
}
