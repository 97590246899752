import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.url = this.element.action

    this.initializeCheckboxes()
    this.initializeDropdowns()
    this.initializeButtons()
  }

  initializeCheckboxes() {
    const self = this

    $(this.element).find('.ui.checkbox').checkbox({
      beforeChecked: function() {
        $(this).parent('.ui.checkbox').checkbox('set indeterminate')

        self.patch(this.name, true).then(res => {
          if (res.data.success) {
            $(this).parent('.ui.checkbox').checkbox('set checked')
          }
        })

        return false
      },
      beforeUnchecked: function() {
        $(this).parent('.ui.checkbox').checkbox('set indeterminate')

        self.patch(this.name, true).then(res => {
          if (res.data.success) {
            $(this).parent('.ui.checkbox').checkbox('set unchecked')
          }
        })

        return false
      },
    })
  }

  initializeDropdowns() {
    const controller = this

    $(this.element).find('.ui.dropdown').dropdown({
      onChange: function(value, text, target) {
        const dropdown = this
        $(this).addClass('disabled loading')

        controller.patch(target.data('id'), value).then(res => {
          if (res.data.success) {
            $(dropdown).removeClass('disabled loading')
          }
        })
      }
    })

    $(this.element).find('.ui.dropdown').each(function(idx) {
      $(this).dropdown('set selected', $(this).data('initial-value'), undefined, true)
    })
  }

  initializeButtons() {
    const controller = this

    $(this.element).find('.ui.button').on('click', event => {
      event.preventDefault()

      const fieldName = $(event.target).data('field')
      const input = $(this.element).find(`*[name=${fieldName}]`)

      $(event.target).addClass('loading')

      controller.patch(fieldName, input.val()).then(res => {
        if (res.data.success) {
          const oldVal = $(event.target).val()

          $(event.target).val('Saved!')
          $(event.target).removeClass('loading')

          setTimeout(() => {
            $(event.target).val(oldVal)
          }, 500)
        }
      })
    })
  }

  patch(id, value) {
    const self = this
    const obj = {}

    obj[id] = value

    return fetch(self.url, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ checklist: obj })
    }).then(res => {
      return res.json()
    }).catch(err => {
      return { data: { success: false } }
    })
  }
}
