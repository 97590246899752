import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const self = this

    $(this.element).find('tbody tr').on('click', function(event) {
      const idVal = $(this).data('item-id')
      if (event.target.tagName !== 'LABEL') {
        Turbolinks.visit(`/work_orders/${idVal}`)
      }
    })
  }
}
