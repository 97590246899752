// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "fomantic-ui"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { displayDates } from "../lib/date"
import { initRecordSearch } from "../lib/record-search"
import { DateTime } from "luxon"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', () => {
  $('.ui.search.dropdown[data-init=1]').dropdown({ fullTextSearch: true })
  $('.ui.dropdown[data-init=1]:not(.search)').dropdown()
  $('.ui.checkbox[data-init=1]').checkbox()
  $('.ui.calendar[data-init=1][data-type=date]').calendar({
    type: 'date',
    onChange: function(date) {
      const val = DateTime.fromJSDate(date)
      $($(this).data('field'))
        .val(val.toFormat('yyyy-MM-dd'))
        .trigger('change')
    },
    minDate: $(this).data('minDate'),
    maxDate: $(this).data('maxDate')
  })

  $('.ui.basic.launch.icon.button').on('click', function(event) {
    event.stopPropagation()
    $('.ui.left.fixed.vertical.menu').addClass('active')

    $('body').on('click', function() {
      $('.ui.left.fixed.vertical.menu').removeClass('active')
    })
  })

  displayDates()
  initRecordSearch()

  $('#per-page').dropdown({
    onChange: function(value) {
      const path = document.location.pathname
      const search = new URLSearchParams(document.location.search)

      search.set('per_page', value)
      search.delete('page')
      Turbolinks.visit(`${path}?${search.toString()}`, { action: 'replace' })
    }
  })
})
