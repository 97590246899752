import { Controller } from "stimulus"

/**
 * Adds numbers on the payments/apply_fields partial
 */
export default class extends Controller {
  static values = {
    amountField: String
  }

  static targets = [
    'charge',
    'prepay',
    'subtotal',
    'total'
  ]

  connect() {
    const self = this

    this.dollarFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    $(this.chargeTargets).checkbox({
      onChange: function() {
        self.refreshAmounts()
      }
    })

    $(this.prepayTargets).on('change', function() {
      self.refreshAmounts()
    })
  }

  refreshAmounts() {
    const self = this
    var subtotal = 0

    this.chargeTargets.forEach(ct => {
      if ($(ct).checkbox('is checked')) {
        subtotal += Number($(ct).data('amount'))
      }
    })
    if (this.hasSubtotalTarget) {
      $(this.subtotalTarget).text(self.dollarFormatter.format(subtotal / 100))
    }

    var total = subtotal
    this.prepayTargets.forEach(pt => {
      const val = $(pt).val()
      if (val.match(/\$?\d+(\.\d\d?)?/)) {
        total += Number(val.replace('$', '')) * 100
      }
    })
    $(this.totalTarget).text(self.dollarFormatter.format(total / 100))

    if (this.hasAmountFieldValue) {
      console.log(this.hasAmountFieldValue, this.amountFieldValue)
      $(this.amountFieldValue).val(self.dollarFormatter.format(total / 100))
    }
  }
}
