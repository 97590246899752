import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  connect() {
    const self = this

    $(self.element).find('button.clear.button').on('click', event => {
      event.preventDefault()
      self.clear()
    })

    $(self.element).find('input[type=text][name]').on('change', event => {
      event.preventDefault()
      self.submitForm()
    })

    $(self.element).find('input[type=hidden][name]').on('change', event => {
      event.preventDefault()
      self.submitForm()
    })

    $(self.element).find('.ui.checkbox').checkbox({
      onChange: function(event) {
        self.submitForm()
      }
    })

    $(self.element).find('.ui.action.input button').on('click', event => {
      event.preventDefault()
      self.submitForm()
    })

    $(self.element).find('.create-dropdown-wrapper .ui.dropdown').on('tenfour:change', event => {
      event.preventDefault()
      self.submitForm()
    })
  }

  submitForm() {
    const self = this
    $(self.element).find('input[type=text]').off('change')
    $(self.element).find('.ui.action.input button').off('click')
    $(self.element).find('.ui.dropdown input[type=hidden]').off('change')

    const fd = new FormData(this.element)
    const url = new URL(document.location)
    const oldParams = new URLSearchParams(document.location.search)
    const newParams = new URLSearchParams()

    for (const pair of fd.entries()) {
      if (pair[1] !== '') {
        newParams.append(pair[0], pair[1])
      }
    }

    for (const pair of url.searchParams.entries()) {
      if (/^sort/.test(pair[0])) {
        newParams.append(pair[0], pair[1])
      }
    }

    if (oldParams.get('per_page')) {
      newParams.set('per_page', oldParams.get('per_page'))
    }

    $(self.element).find('.ui.checkbox').each(function(index) {
      const paramName = $(this).find('input[type=checkbox]').attr('name')
      newParams.delete(paramName)
      if ($(this).checkbox('is checked')) {
        newParams.append(paramName, '1')
      }
    })

    const query = newParams.toString()
    let newUrl = this.element.action

    if (query) {
      newUrl = `${newUrl}?${query}`
    }

    Turbolinks.visit(newUrl, { action: 'replace' })
  }

  clear() {
    const oldParams = new URLSearchParams(document.location.search)
    const search = new URLSearchParams()

    if (oldParams.get('per_page')) {
      search.set('per_page', oldParams.get('per_page'))
    }

    const url = `${this.element.action}?${search.toString()}`
    Turbolinks.visit(url, { action: 'replace' })
  }
}
