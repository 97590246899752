import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const self = this

    this.vehicleField = $(this.element).find('input[name="task[vehicle_id]"]')
    this.destinationField = $(this.element).find('input[name="task[location]"]')
    this.notesField = $(this.element).find('textarea[name="task[notes]"]')
    this.kindField = $(this.element).find('input[name="task[kind]"]')

    if (this.vehicleField.length === 1) {
      this.vehicleField.on('change', function() {
        const vehicleId = $(this).val()
        if (vehicleId) {
          Turbolinks.visit(`/tasks/new?vehicle_id=${vehicleId}`, { action: 'replace' })
        }
      })
    }

    if (this.kindField.val()) {
      self.handleKind(this.kindField.val())
    }

    this.kindField.on('change', function() {
      self.handleKind($(this).val())
    })
  }

  handleKind(val) {
    if (val === 'transfer' || val === 'move_in') {
      if (this.destinationField.parents('.field').hasClass('disabled')) {
        this.destinationField.parents('.field').removeClass('disabled')
        this.destinationField
          .parent()
          .dropdown('set exactly', this.destinationPrevValue)
      }
    } else {
      this.destinationPrevValue = this.destinationField
        .parent()
        .dropdown('get value')
      this.destinationField.parent().dropdown('clear')
      this.destinationField.parents('.field').addClass('disabled')
    }

    if (val === 'miscellaneous') {
      this.notesField.parent().addClass('required')
    } else {
      this.notesField.parent().removeClass('required')
    }
  }
}
